import { useBrandQuery } from 'src/generated/graphql';
import { useBrandId } from 'src/hooks/useBrandId';

function BrandHomePage(): JSX.Element {
  const brandId = useBrandId();

  const { data } = useBrandQuery({
    variables: {
      id: brandId,
    },
  });

  return (
    <div>
      <p className="text-lg">
        Welcome to the {data?.brand.name} AddGlow dashboard.
      </p>
    </div>
  );
}

export default BrandHomePage;
